import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import ITRoutes from './router/index';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider>
    <ITRoutes/>
  </ConfigProvider>
);

reportWebVitals();
