import axios from "axios";

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params,}).then((response) => {
            const _res = JSON.parse(JSON.stringify(response.data));
            if(_res!=null){
                if(_res['code']===200||_res['status']==="success"){
                    return resolve(_res);
                }
                if(_res['status']==="error"){
                    window.location.href ="/"
                }
            }
            return reject(_res);
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, _data) {
    return new Promise((resolve, reject) => {
        axios.post(url, _data,{headers: {'Content-Type': 'multipart/form-data'},transformRequest: (data, headers) => _data}).then((response) => {
            const _res = JSON.parse(JSON.stringify(response.data));
            if(_res!=null){
                if(_res['code']===200||_res['status']==="success"){
                    return resolve(_res);
                }
                if(_res['status']==="error"){
                    window.location.href ="/"
                }
            }
            return reject(_res);
        }).catch((error) => {
            reject(error);
        });
    });
}

