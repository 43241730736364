import React,{ITComponent,Fragment,ITBase} from 'comp/index';
import {Outlet,Link} from 'react-router-dom';

import { Layout,Menu,Row, Col} from 'antd';

import './index.less';

const { Header, Footer, Content } = Layout;

class DefaultLayoutView extends ITComponent{
    constructor(props){
        super(props);
        this.state = {menusSource:[]}
    }
    static defaultProps = {
    }
    componentDidMount=()=>{
        this.onLoadMenu();
    }

    onLoadMenu = async ()=>{
        const menusList= this.renderMenus(ITBase.menus);
        this.setState(state=>{
            const db = [];
            this._menuSource(menusList,db);
            state.menusSource = db;
            return state;
        });
    }

    renderMenus=(source)=>{
        return source.map(l=>{
            if(l.isLeaf===true){
                const children = this.renderMenus(l.subMenu);
                return {key:l.code,label:l.name,children:children};
            }
            return {key:l.code,label:(<Link to={l.path}><span>{l.name}</span></Link>)};
        });
    }
    _menuSource=(source,db)=>{
        for(let s in source){
            if(source[s].isLeaf===true){
                this._menuSource(source[s].subMenu,db);
            }else{
                db.push(source[s]);
            }
        }
    }

    render(){
        const {menusSource} = this.state;
        return (
        <Fragment>
            <Layout>
                {/* <Header className='it-theme-header'>
                <Row wrap={false}>
                    <Col flex="auto"> */}
                        {/* <Row wrap={false} > */}
                            {/* <Col flex="220px"></Col> */}
                            {/* <Col flex="auto">
                            <Menu
                            mode="horizontal"
                            style={{
                                height: '56px',
                            }}
                            items={menusSource}
                            />
                            </Col>
                        </Row>
                    </Col>
                    <Col flex="200px"></Col>
                </Row>
                </Header> */}
                <div className='it-theme-content' >
                <Layout>
                    <Layout style={{padding:0,overflowX: "auto",overflowY: "none",scrollbarWidth: "thin"}}>
                        <Content style={{"padding": "5px 12px 12px",minHeight: 800,background:"#f0f2f5"}} className='main-wrapper'>
                            <Outlet context = {{}}/>
                        </Content>
                    </Layout>
                </Layout>
                </div>
                <Footer plain='true'>
                    <div className={'banner'}></div>
                </Footer>
            </Layout>
        </Fragment>
        );
    }
}
export default DefaultLayoutView;