const ITBase = {
    basepath:"http://127.0.0.1",

    list:{
        IT_ChartsView:{path:"/content/home",code:"IT_ChartsView",desc:"數據頁面",only:false},
        IT_MonitorView:{path:"/content/monitor",code:"IT_MonitorView",desc:"監控頁面",only:false}
    },

    menus:[
        {path:"/content/data",name:"數據",code:"DATA",isLeaf:false},
        {path:"/content/monitor",name:"監控",code:"MONITOR",isLeaf:false},
    ],
};

export default ITBase;