import React,{ITBase,ITComponent,useEffect,ITID} from 'comp/index';
import { BrowserRouter, Routes, Route,Navigate,useLocation,useNavigate,useOutletContext  } from "react-router-dom";

import ITLayout from 'layout/index';

function Layout(props){
    const location = useLocation();
    const navigate = useNavigate();
    const context = useOutletContext();
    useEffect(() => {
     }, [location,navigate]);
    return (<ITLayout {...{location,navigate,context}}/>);
}
function Element(props){
    const location = useLocation();
    const navigate = useNavigate();
    const context = useOutletContext();
    const Elm = props.el;
    useEffect(() => {
     }, [location,navigate]);
     return <Elm {...{location,navigate,context}}/>
}
class ITRoutes extends ITComponent {
    constructor(props){
        super(props);
        this.state = {}
    }
    componentDidMount=async()=>{
        await this.renderRoutes();
    }
    renderRoutes=async()=>{
        const _componentList = await import("conf/manager");
        const keyList = Object.keys(ITBase.list);
        const componentsList = keyList.map(e=>{
            const x = ITBase.list[e];
            const itClass = _componentList[x.code];
            const data = Object.assign({},{el:<Element el={itClass} key={ITID()}/>},{"path":x.path,"only":x.only,key:e});
            return data;
        });

        const componentsOnlyList = componentsList.filter(e=>{
            return e.only===true;
        });

        const componentsFrameList = componentsList.filter(e=>{
            return e.only===false;
        });
        this.setState(state=>{
            state.frames = componentsFrameList;
            state.onlys = componentsOnlyList;
            return state;
        });
    }
    render() {
        if(!this.state.onlys||!this.state.frames)return;//判断是否已经加载完毕
        const frames = this.state.frames||[];
        const onlys = this.state.onlys||[];
        return (
            <BrowserRouter>
                <Routes>
                    {
                        onlys.filter(e=>{
                            return e.el!==undefined;
                        }).map(element => {
                            return <Route path={element.path} element={element.el} key={ITID()}/>
                        })
                    }
                    <Route path="/" element={<Layout/>}>
                        {
                            frames.filter(e=>{
                                return e.el!==undefined;
                            }).map(element => {
                                if(element.path==="/content/home"){
                                    return <Route index  element={element.el} key={ITID()} />//forceRefresh={true}
                                }else if(element.path!=="/content/home"){
                                    return <Route path={element.path} element={element.el} key={ITID()} />//forceRefresh={true}
                                }
                                return null;
                            })
                        }
                        <Route path="*" element={<Navigate to='/' replace={true}/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}
export default ITRoutes;