import React, {Component} from 'react';
import "antd/dist/reset.css";

export class ITComponent extends Component{
    componentDidUpdate(){}
}
export {Fragment,useEffect,useState,useRef,useCallback,useId} from 'react';
export default React;
export { default as ITBase} from "conf/index"
export {nanoid as ITID} from "nanoid"


export { get,post } from 'utils/http';