import React,{ITComponent,Fragment} from 'comp/index';

import './index.less';
import DefaultLayoutView from 'layout/default'

class ITLayout extends ITComponent { 
    constructor(props){
        super(props);
        this.state = {teamView:{}};
    }
    static getDerivedStateFromProps(props,state){
        return null;
    }
    componentDidMount=async()=>{}
    render() {
        return (
            <Fragment>
                <DefaultLayoutView {...{navigate:this.props.navigate}}/>
            </Fragment>
        );
    }
}
export default ITLayout;